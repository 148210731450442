.swiper-animate-back {
    animation: scan-back 1s ease-in-out infinite normal ;
}
.swiper-animate-front {
    animation: experience-scan-front 1s ease-in-out infinite normal;
}
@keyframes scan-back {
    from {
        height: 0;
    }
    to {
        height: 100%;
    }
}
@keyframes experience-scan-front {
    from {
        top:-83px;
    }
    to {
        top: calc(100% - 83px);
    }
}
.scan-loading{
    position: relative;
    overflow: hidden;
}
.scan-loading-front{
    position: absolute;
    z-index: 3;
    width: 100%;
}
.scan-loading-back{
    width: 100%;
    position: absolute;
    z-index: 2;
}
.scan-mask{
    z-index: 1;

    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0,0,0,0.5);
}
