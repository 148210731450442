html,body{
  height:100%;
  width: 100%;
  min-height: 100%;
  position: relative;
  background-image: linear-gradient(-135deg, #2B87E5 8%, #3E7DEF 100%);
  background: #F0F3F9;
}

.login{
  min-height: 600px;
  height:100%;
  width: 100%;
  min-height: 100%;
  position: relative;
  min-width:1368px;
}
.login .loginPanel{
  background: #FFFFFF;
  box-shadow: 0 6px 20px 0 rgba(142,159,209,0.16);
  border-radius: 4px;
  height: 344px;
  width: 760px;
  margin:0 auto;
  display: flex;
  display: -webkit-flex;
  position: absolute;
  top: 43%;
  left: 50%;
  -webkit-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
}

.login .loginPanel .loginLeft{
  width: 431px;
  max-width: 431px;
  min-width: 431px;
  position: relative;
}

.login .loginPanel .loginLeft::before{
  content: '';
  position: absolute;
  border-right:1px solid #A6AAB0;
  height: 224px;
  top: 50%;
  left: 100%;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);

  opacity: 0.1;
  background: #A6AAB0;
}

.login .loginPanel .loginLeft img{
  width: 100%;
  height: 100%;
}

.login .loginPanel .loginRight{
  min-width: 230px;
  max-width: 260px;
  width: 100%;
  margin: 0 auto;
  height: 224px;
  position: relative;
  top: 60px;
}

.login .loginPanel .loginRight h2{
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #43485C;
  letter-spacing: 0;
  line-height: 20px;
}

.login .loginPanel .loginRight img{
  width: 15px;
  height: 15px;
}


.login .loginPanel .loginRight .ant-input{
  border: 0px;
  border-bottom: 0.5px solid rgba(33, 42, 58, 0.16);
  border-radius: 0;
  padding-left: 40px;
  outline:none;
  background: transparent;
  appearance: none;
  outline-style: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

.login .loginPanel .loginRight .ant-input:-webkit-autofill{
  box-shadow: 0 0 0 38px white inset;
}
.login .loginPanel .loginRight .ant-input::before{ border: none; }
.login .loginPanel .loginRight .ant-input::after{ border: none; }

.login .loginPanel .loginRight .ant-input::placeholder {
  opacity: 0.3;
  font-family: PingFang-SC-Medium;
  font-size: 14px;
  color: #212A3A;
  line-height: 14px;
}

.login .loginPanel .loginRight .ant-input:focus{
  outline:none;
  border-bottom:0.5px solid #298AEC;
  box-shadow: none;
  background: transparent;
}

.login .loginFooter{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;


  text-align: center;
  margin:0 auto;




}
.login .loginFooter div{
  display: inline-flex;
  margin: 0 auto;
  text-align: center;
}

.login .loginFooter div section{
  margin-right: 16px;
  opacity: 0.5;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #43485C;
  letter-spacing: 0;
  text-align: center;
  line-height: 60px;
}
.login .loginFooter div section a{
  font-size: 12px;
  color: #43485C;
  letter-spacing: 0;
  text-align: center;
  line-height: 60px;
}

.login .Security{
  background: url('/img/login/security.png') no-repeat center center;
  background-size: 100%;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 3px;
}
.login .loginTip{
  opacity: 0.5;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #43485C;
  letter-spacing: 0;
  line-height: 12px;
  position: relative;
  top: 21px;
  text-align: left;
}
