.card{
  padding: 45px 60px;
  /*height: calc(100vh - 116px);*/
  height: auto;
  width: 100%;
  overflow: auto;
  margin: 16px;
  width: 98%;
}
.taskTitle{
  opacity: 0.9;
  font-family: PingFangSC-Semibold;
  font-size: 28px;
  color: #212A3A;
  line-height: 30px;
  position: relative;
}
.taskTitle .opend{
  position: absolute;
  right: 30px;
  top: -35px;
}

.safetyScore{
  opacity: 0.9;
  font-weight: 600;
  font-size: 20px;
  color: #212A3A;
  line-height: 1;
  margin: 24px 0;
}
.score span{
  font-family: DINCondensed-Bold;
  font-size: 36px;
  color: #298AEC;
  line-height: 26px;
}
.score span:last-child{
  font-size: 18px;
}

.flex{
  display: flex;
  display: -webkit-flex;
}

.detailScore{
  padding:12px 0 18px;
  border-bottom:1px solid rgba(201,212,230,0.50);
  margin-bottom:18px;
  width: 100%;
  line-height: 1;
}

.detailScore div{
  margin-right: 40px;
  opacity: 0.9;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #212A3A;
}
.detailScore div span{
  opacity: 0.9;
  color: #298AEC;

}
.scoreItem{
  font-family: DINCondensed-Bold;
  font-size: 17px;
  position: relative;
  top:1px
}
.testEchart{
  border-bottom:1px solid rgba(201,212,230,0.50);;
  padding-bottom: 20px;
}
.testEchart .title{
  opacity: 0.9;
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #212A3A;
  line-height: 20px;
}

.testEchart .chart{


}

.testEchart .chart .left,.testEchart .chart .right{
  margin-top: 20px;
}
.testEchart .chart .left .attackDetails,.testEchart .chart .right .attackDetails{
  opacity: 0.9;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #212A3A;
  text-align: center;
  line-height: 16px;
  display: block;
}

.testEchart .chart .left{
  /*
  width: 540px;
  max-width: 540px;
  min-width: 540px;
  flex:1;
  -webkit-flex:1;
  margin-right: 16px;
  */
}
.testEchart .chart .right{
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}
.testEchart .chart .title{
  opacity: 0.9;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #212A3A;
  line-height: 14px;
  margin-bottom: 12px;
}
.testEchart .chart .attack {
  width: 100%;
}
.testEchart .chart .attack div{
  width: 50%;
}
.testEchart .chart .attack div:first-child{
  margin-right: 12px;
}

.case{
  border-bottom:1px solid rgba(201,212,230,0.50);;
  padding-bottom: 20px;
  width: 100%;
}

.case .title{
  opacity: 0.9;
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #212A3A;
  line-height: 20px;
  margin: 20px auto;
}
.case .caseOne{
  width: 100%;
  overflow: auto;
}
.case .caseOne section{
}

.case .caseOne .left{
  margin-right:30px;
}
.case .caseOne .right{
  width: 283px;
  /*background-color: rgba(230,235,243,0.20);*/
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #212A3A;
  line-height: 14px;
  height:378px;
  background-color: rgba(230,235,243,0.60);
}


.suggestions{
  border-bottom:1px solid rgba(201,212,230,0.50);;
  padding-bottom: 20px;
}
.suggestions .title{
  opacity: 0.9;
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #212A3A;
  line-height: 20px;
  margin: 20px auto;
}
.suggestions article section{
  opacity: 0.9;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #212A3A;
  line-height: 21px;
}

.suggestions article section a{
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #0F0000;
  line-height: 21px;
}

.detailsButton{
  text-align: center;
  cursor: pointer;
}

.safetyScoreQuestion{
  left: 6px;
  color: #298AEC;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.breadcrumb{
  cursor: pointer;
}
