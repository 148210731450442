.personalTableParent{
  height: calc(100% - 200px);
  position: relative;
}


.customerPersonal{
  overflow: auto;
}

.customerPersonal .personalTable{
  margin-top: 20px;
}

/*
.customerPersonal .personalTable .ant-spin-nested-loading::after{
  content: '';
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(-180deg, rgba(255,255,255,0.00) 0%, #ffffff 100%);
}
*/

/*
.customerPersonal .personalTable table tbody tr:last-child{
  position: relative;
}
.customerPersonal .personalTable table{
  content: '';
  background-image: linear-gradient(-180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%);
  box-shadow: 1px 1px 1px 1px inset red;
} */

.rightArrow::before{
  content: '';
  position: relative;
  top: -52px;
  width: 5px;
  left: -19px;
  height: 5px;
  border: 8px solid;
  border-color: transparent transparent #ffffff transparent;
  display: block;
  float: right;
}
.receipts{
  border-top: 1px solid rgba(201,212,230,0.50);
  padding: 24px 0 0;
  margin-top: 24px;
}

.receipts span{
  opacity: 0.9;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #212A3A;
  line-height: 16px;
}

/*.content{
  overflow: auto;
}*/

.ant-table-placeholder{
  border-bottom: none;
}
