.helpWrap{
    position: absolute;
    height: 100%;
    left: 20px;
    width: calc(100% - 40px);

}
.help{
    overflow: hidden;
    width: 100%;
    background: #fff;
}
.helpLeft{
    width: 300px;
    position: absolute;
    left: 0;
    background: #fff;
    overflow: auto;
    height: 100%;
    padding: 36px;

}
.helpRight{
    position: absolute;
    width: calc(100% - 300px);
    background: #fff;
    height: 100%;
    right: 0;
    overflow: auto;
    border-left: 1px solid #D1E3F6 ;
    padding: 36px;


}
