.card{
    width: 335px;
    height: 158px;
    margin-right: 20px;
    display: inline-block;
    padding: 24px 24px 0 24px;
    border-radius: 4px;
    border:1px solid #C9D4E6;
    margin-bottom: 20px;
}
.title{

}
.price{
    text-align: right;
    font-size: 24px;
    line-height: 24px;
    height: 24px;
}
.operate{
    margin-top: 14px;
    height: 47px;
    border-top: 1px solid rgb(201, 212, 230);
}
.priceIcon{
    font-size: 18px;
    line-height: 24px;
    vertical-align: text-bottom;
}
.priceNum{
    color: #EC8D42;
    opacity: 0.9;

    margin-left: 8px;
    line-height: 24px;
    display: inline-block;

}
.year{
    font-size: 12px;

}
.token{
    flex: 1;
    text-align:center ;
    border-left: 1px solid rgb(201, 212, 230);

}
.sdk{
    flex: 1;
    text-align:center ;

}
.hidden{
    position: fixed;
    opacity: 0;
    width: 10px;
    left: -10px;
}
