.swiper-slide{
  width: 120px !important;
}

.swiper-container{
  width: 594px;
  margin: 0;
  cursor: pointer;
  /*max-width: 740px;
  min-width: 740px;*/

}

.swiper-button-prev.swiper-button-disabled,.swiper-button-next.swiper-button-disabled{
  opacity: 0;
}

