.headerContainer-exitUserName{
  cursor: pointer;
  min-width: 60px;
  color: rgb(33, 42, 58);

}
.headerContainer-user img{
  width: 26px;
  margin-right: 5px;
  height: 26px;
  font-size: 20px;
  position: relative;
  top: 1px;
}
.headerContainer-personal{
  padding: 1px 0 5px;
  margin-bottom: 5px;
  font-size: 14px;
  border-bottom: 1px solid #efefef;
}
.headerContainer-personal:hover,.headerContainer-exit:hover{
  color:#52acfa;
}
.headerContainer-help{
  color: rgb(33, 42, 58);
}
