.error403{
  background: #ffffff;
  margin: 12px;
  border-radius: 5px;
  height: auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}