* {
  margin-bottom: 0;
  padding: 0;
}
.ui-container-padding {
  width: 100%;
  padding: 20px  20px 0;
  height: 100%;
}
.ui-container {
  width: 100%;
  position: relative;
  height: 100%;
}
.ui-breadcrumb {
  line-height: 16px;
  height: 16px;
  margin-bottom: 20px;
}
.ui-breadcrumb .ant-breadcrumb {
  font-size: 16px;
  line-height: 16px;
}
.ui-breadcrumb .ant-breadcrumb {
  display: inline-block;
}
/*.ui-ct {
    border: 1px solid #ececec;
    min-width: unit((1368-180-20-20),px);
    position: relative;
    background: #fff;
}*/
.ui-hd {
  height: 30px;
  line-height: 30px;
  font-size: 30px;
  color: #212A3A;
  opacity: 90%;
  margin-bottom: 0;
}
.ui-bd {
  background: #fff;
  overflow: auto;
  height: calc(100%);
}
.ui-bd-transparent {
  overflow: auto;
  height: calc(100%);
}
.ui-bd-bread {
  background: #fff;
  overflow: auto;
  height: calc(100% - 36px);
}
.ui-bd-btn {
  background: #fff;
  overflow: auto;
  height: calc(100% - 53px);
}
.ui-bd-ct {
  min-width: 1148px;
  min-height: 668px;
}
.ta-l {
  text-align: left;
}
.ta-c {
  text-align: center;
}
.ta-r {
  text-align: right;
}
.fl-r {
  float: right;
}
.fl-l {
  float: left;
}
.w-half {
  width: 50%;
}
.d-b {
  display: block;
}
.d-n {
  display: none;
}
.d-ib {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
.align-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flex-center {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
}
.f-cb:after {
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  content: ".";
}
.t-blue {
  color: #298AEC;
}
.t-red {
  color: #F45343;
}
.t-gray {
  color: rgba(33, 42, 58, 0.5);
}
.t-green {
  /*color:#0ECB8A*/
  color: #52c41a;
}
.t-white {
  color: rgba(255, 255, 255, 0.9);
}
.t-orange {
  color: #faad14;
}
.t-opa50 {
  opacity: 0.5;
}
.t-opa90 {
  opacity: 0.9;
}
.t-bold {
  font-weight: bold;
}
.bg-center {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.t-ul {
  text-decoration: underline;
}
.t-ul:hover {
  text-decoration: underline;
}
h2 {
  font-size: 30px;
  font-weight: 500;
}
h3 {
  font-size: 20px;
  font-weight: 500;
}
h4 {
  font-size: 16px;
  font-weight: 600;
}
h5 {
  font-size: 14px;
  font-weight: 600;
}
.ant-btn {
  font-size: 14px;
}
.ant-btn-lg {
  width: 160px;
  height: 40px;
  font-size: 16px;
}
/*antd 上传中，删除按钮被遮挡，固修改*/
.ant-upload-list-item-progress {
  width: calc(100% - 26px);
}
.anticon-delete {
  padding-left: 6px;
}
.ant-table-column-title {
  font-weight: 600;
}
/*@base-orange:#faad14;*/
/*@background-color-light:fade(@base-gray,50);*/
