.evaluationBox{
    background: #FFFFFF;
    border: 1px solid #C9D4E6;
    border-radius: 4px;
    display: inline-grid;
    padding:0 22px;
    margin-right:16px;
    margin-bottom:16px;
    width:335px;
    height:140px;
    position:relative;
}
.title {
    position: relative;
    display: flex;
    display: -webkit-flex;

}
.top{
    height: 84px;
    padding-top: 22px;

}
.bottom{
    padding-bottom: 20px;
    height: 56px;
    font-size: 12px;
    position: relative;
}
.titleName{
    margin-right: 12px;
    line-height: 14px;
    display: inline-block;
    max-width: 225px;
}
.title span {
    opacity: 0.9;
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #212A3A;
    width:222px;
    min-width:42%;
    display:block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.status {
    display: inline-block;
    padding: 1px 6px;
    border-radius: 18px;
    font-size: 12px;
    left: 11px;
    color: #ffffff;
    position: relative;
}

.red {
    background: #f45343;
    border: 1px solid #f45343;
}

.red.text {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #F45343;
    line-height: 12px;
    position: relative;
    top: 12px;
}

.status.pinggu {
    background: #2d8cec;
    border: 1px solid #2d8cec;
}

.time {
    opacity: 0.5;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #212A3A;
    margin-top: 12px;
    line-height: 12px;
}

.pinggu.icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    bottom: 0;
    margin: 0 auto;
}

.pinggu.text {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #298AEC;
    margin-top: 5px;
    display: block;
    position: relative;
    top: 9px;
}
.open{
    color: #298AEC;
    line-height: 14px;
    cursor:pointer;
    position: absolute;
    bottom: 20px;
    font-weight: 600;
}
.icon{
    margin-left: 4px;
    font-size: 12px;
}
.info{
    position: absolute;
    bottom: 20px;
}
.evaluationModalItem{
    margin-bottom: 0.5em;
}
