.scanning{
  position: relative;
  height: 100%;
}
.scanning::before{
  content:'';
  /**
  background: url('../../../../../public/img/distinguish/scan-back.png') no-repeat center center;
  background-size: 100% 77%;
  */
  background: rgba(33, 42, 58, 0.2);
  height: 88%;
  position: absolute;
  display: block;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.scanning::after{
  content:'';
  background: url(/media/scan-back.99af5bab.png) no-repeat center center,url(/media/scan-line.0096f653.png) no-repeat center center;
  background-size: 100% 77%;
  position: absolute;
  display: block;
  z-index: 2;
  left: 0;
  right: 0;
  top:0;
  bottom: 0;
  height: 85px;
}

.animate-back::beofre {
  animation: scan-back 1s ease-in-out infinite normal ;
}
.animate-front::after{
  animation: scan-front 1s ease-in-out infinite normal;
}

@keyframes scan-back {
  from {
      height: 0;
  }
  to {
      height: 100%;
  }
}
@keyframes scan-front {
  from {
    bottom:-115px;
  }
  to {
    top: calc(100% - 115px);
  }
}


.Container{
  width: 100%;
  min-height: 330px;
  margin-top: 20px;
}
.Container section{
  display: flex;
  display: -webkit-flex;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}

.Container section .imgBox,.Container section .imgBox .imgData{
  position: relative;
  
  width: 297px;
  height: 297px;
  min-height: 297px;
  max-height: 297px;
  min-width: 297px;
  max-width: 297px;
  background: rgba(230,235,243,0.20);
}

.Container section .imgBox .imgData{
  display: flex;
  align-items: center;
  justify-content: center;
}

.Container section .imgBox:last-child{
  border-left:1px solid #ffffff;
}

.Container section div img{
  display: block;
  max-width: 297px;
  max-height: 297px;
  /*width: 100%;
  height: 100%;*/
}
.Container section .imgName{
  opacity: 0.9;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #212A3A;
  text-align: center;
  line-height: 40px;
  display: block;
}

.Container ul{
  position: absolute;
  padding: 0;
  margin: 0;
  list-style: none;
  left: 16px;
  top: 16px;
  bottom: 16px;
  z-index: 5;
}
.Container ul li{
  width: 60px;
  height: 60px;
  
  background: rgba(230,235,243,0.2);
  display: inline-block;
  cursor: pointer;
  margin: 0 20px 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 10px 0px rgba(33,42,58,0.6);
  position: relative;

}
.Container ul li::after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  min-width: 60px;
  min-height: 60px;
  bottom: 0;
  border:4px solid rgba(255, 255, 255, 0.7);
}
.Container ul li span{
  max-width: 60px;
  max-height: 60px;
  display: block;
}
.Container ul li img{
  max-width: 52px;
  max-height: 52px;
  margin: 0 auto;
}
.Container ul li.active::after{
  border: 4px solid #298AEC;
}

.Container .ant-spin-nested-loading > div > .ant-spin{
  background-color: rgba(33, 42, 58,0.5);;
}

.Container section .imgBox .imgData .imgContain{
  width: 100%;
  height: 100%;
  background-size: contain !important;
}